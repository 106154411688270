/*------------------------------------------------
// File Name:PDM_MA_Schd.js
// File Description:PDM_MA_Schd API方法js接口
// Author:zxl
// Create Time:2023/06/06 11:19:48
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maSchd: "/PDM_MA_Schd"
}

export default api


//-------------------创建预约排班对象-------------------
export function CreatePDM_MA_SchdEntity(parameter) {
    return axios({
        url: api._maSchd + "/CreatePDM_MA_SchdEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约排班列表-------------------
export function GetPDM_MA_SchdList(parameter) {
    return axios({
        url: api._maSchd + "/GetPDM_MA_SchdList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取预约排班列表---------------
export function GetPDM_MA_SchdListByPage(parameter) {
    return axios({
        url: api._maSchd + "/GetPDM_MA_SchdListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约排班对象---------------
export function GetPDM_MA_SchdEntity(parameter) {
    return axios({
        url: api._maSchd + "/GetPDM_MA_SchdEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存预约排班对象---------------
export function UpdatePDM_MA_SchdEntity(parameter) {
    return axios({
        url: api._maSchd+ "/UpdatePDM_MA_SchdEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉预约排班对象---------------
export function DeletePDM_MA_SchdEntity(parameter) {
    return axios({
        url: api._maSchd + "/DeletePDM_MA_SchdEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------启用/禁用---------------
export function UpateIsValid(parameter) {
    return axios({
        url: api._maSchd + "/UpateIsValid",
        method: 'post',
        data: parameter
    })
}

//-------------------生成排班---------------
export function CreateDate(parameter) {
    return axios({
        url: api._maSchd + "/CreateDate",
        method: 'post',
        data: parameter
    })
}

//-------------------点击日期重新生成排班---------------
export function ClickDay(parameter) {
    return axios({
        url: api._maSchd + "/ClickDay",
        method: 'post',
        data: parameter
    })
}
//-------------------保存排班表对象--上下午时间---------------
export function UpdatePDM_MA_Schd(parameter) {
    return axios({
        url: api._maSchd + "/UpdatePDM_MA_Schd",
        method: 'post',
        data: parameter
    })
}


//-------------------获取预约排班列表--后台添加患者预约使用---------------
export function GetPDM_MA_SchdData(parameter) {
    return axios({
        url: api._maSchd + "/GetPDM_MA_SchdData",
        method: 'post',
        data: parameter
    })
}