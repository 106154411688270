<template>
  <q-card flat>
    <q-card-section class="q-pa-sm">
      <div class="row ">
        <div class="col-3 justify-center">
          <div class="row justify-center">
            <q-date
              minimal
              :disable="PdmMaSchdListLoading"
              v-model="PaiBanOtherData"
              @update:model-value="onclickDay"
              @navigation="onclickDate"
            />
          </div>
          <div>
            <q-tabs
              v-model="tab"
              dense
              active-color="primary"
              indicator-color="primary"
              align="justify"
              narrow-indicator
            >
              <q-tab name="shangwu" label="上午" />
              <q-tab name="xiawu" label="下午" />
            </q-tabs>
            <q-separator />
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="shangwu">
                <ShangWu />
              </q-tab-panel>

              <q-tab-panel name="xiawu">
                <XiaWu />
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </div>
        <div class="col-9">
          <div class="row q-gutter-sm q-mb-sm">
            <!-- <div>
              <q-input
                v-model.number="modelYears"
                type="number"
                outlined
                label="年份"
                style="max-width: 200px"
                dense
              />
            </div>
            <div>
              <q-input
                v-model.number="modelMonth"
                type="number"
                outlined
                label="月份"
                style="max-width: 200px"
                dense
              />
            </div> -->
            <q-input
              outlined
              class="col-2"
              dense
              v-model="FilterString"
              label="套餐名称"
              color="primary"
            />
            <q-btn
              color="primary"
              icon="search"
              @click="onLoadData"
              :loading="PdmMaSchdListLoading"
              style="height: 38px;"
            >
              查询
            </q-btn>

            <q-btn
              color="primary"
              @click="onCreateDate(1)"
              :loading="PdmMaSchdListLoading"
              style="height: 38px;"
            >
              生成排班
            </q-btn>
            <q-btn
              color="primary"
              @click="onClickItem"
              :loading="PdmMaSchdListLoading"
              style="height: 38px;"
            >
              选择套餐
            </q-btn>
            <q-space />
            <!-- <q-btn color="primary" icon="download" style="height: 38px;" @click="onDownload" :loading="WxHomeimageListLoading">
              下载
            </q-btn>
            <q-btn color="primary" style="height: 38px" icon="fas fa-file-excel" :loading="PdmBodycheckPaibanListLoading" @click="onExportData">导出</q-btn> -->
            <!-- <q-btn
          color="primary"
          style="height: 38px"
          icon="note_add"
          :loading="PdmBodycheckPaibanListLoading"
          @click="onAdd"
          >新增</q-btn
        > -->
          </div>
          <vxe-grid
            v-bind="gridOptions"
            :sort-config="{ multiple: true }"
            :custom-config="{ storage: true }"
            id="custom-config"
            :export-config="gridOptions.tableExport"
            :height="$store.getters.maxPageHeight - 140"
            :loading="PdmMaSchdListLoading"
            highlight-current-row
            size="mini"
          >
            <template #toolbar_buttons id="custom-config"> </template>
            <template #IsValidName="{ row }">
              <q-badge :color="row.IsValid == 0 ? 'positive' : 'negative'">
                {{ row.IsValidName }}
              </q-badge>
            </template>
            <template #operate="{ row }">
              <vxe-button
                flat
                color="primary"
                icon="fas  fa-edit"
                @click="onEdit(row)"
                >编辑</vxe-button
              >
              <!-- <vxe-button
            flat
            color="negative"
            icon="fas fa-trash"
            @click="onDelete(row)"
            >删除</vxe-button
          > -->
              <vxe-button flat color="negative" @click="onIsValid(row)">{{
                row.IsValid == 0 ? "禁用" : "启用"
              }}</vxe-button>
            </template>
          </vxe-grid>
          <vxe-pager
            perfect
            size="mini"
            :page-sizes="tablePage.pageSizes"
            v-model:current-page="tablePage.currentPage"
            v-model:page-size="tablePage.pageSize"
            :total="tablePage.totalResult"
            :layouts="[
              'PrevPage',
              'JumpNumber',
              'NextPage',
              'FullJump',
              'Sizes',
              'Total',
            ]"
            @page-change="handlePageChange"
          >
          </vxe-pager>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <q-dialog v-model="pdmMaSchdshowDlg" persistent>
    <MaSchdEdit @onLoadData="onLoadData" />
  </q-dialog>
  <q-dialog v-model="pdmMaSchdtimeshowDlg" persistent>
    <paiBanTime @onLoadData="onLoadData" />
  </q-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
import XEUtils from "xe-utils";
import dlg from "@/config/dialog";
import MaSchdEdit from "./maSchdEdit.vue";
import paiBanTime from "../paiBanTime/index.vue";
import ShangWu from "./shangWu.vue";
import XiaWu from "./xiaWu.vue";
import * as Paiban from "@/api/pdm/ma/maSchd.js";
export default {
  components: {
    MaSchdEdit,
    paiBanTime,
    ShangWu,
    XiaWu,
  },
  computed: {
    ...mapState("PdmMaSchd", [
      "PdmMaSchdList",
      "PdmMaSchdListLoading",
      "PdmMaSchdEntity",
      "PdmMaSchdShowDlg",
    ]),
    ...mapState("PdmMaSchdtime", [
      "PdmMaSchdtimeList",
      "PdmMaSchdtimeListLoading",
      "PdmMaSchdtimeEntity",
      "PdmMaSchdtimeShowDlg",
    ]),

    pdmMaSchdshowDlg: {
      get() {
        return this.PdmMaSchdShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaSchd/setPdmMaSchdShowDlg", val);
      },
    },
    pdmMaSchdtimeshowDlg: {
      get() {
        return this.PdmMaSchdtimeShowDlg;
      },
      set(val) {
        console.log(val);
        this.$store.commit("PdmMaSchdtime/setPdmMaSchdtimeShowDlg", val);
      },
    },
  },
  data() {
    return {
      FilterString: "",
      tab: "shangwu",
      PaiBanDate: [],
      PaiBanOtherData: [],
      modelYears: new Date().getFullYear(),
      modelMonth: new Date().getMonth() + 1,
      tablePage: {
        currentPage: 1,
        pageSize: 20,
        totalResult: 0,
        pageSizes: [15, 20, 50, 100, 5000],
      },
      gridOptions: {
        border: true,
        resizable: true,
        showOverflow: true,
        highlightHoverRow: true,
        align: "left",
        toolbarConfig: {
          perfect: true,
          print: true,
          zoom: true,
          custom: true,
          export: true,
          slots: {
            // 自定义工具栏模板
            buttons: "toolbar_buttons",
          },
        },
        //点击编辑必须配置
        // editConfig: {
        //   trigger: "click",
        //   mode: "cell",
        //   activeMethod: () => {
        //     if (this.lockData) return false;
        //     return true;
        //   },
        // },
        tableExport: {},
        columns: [
          { type: "seq", width: 50, align: "center" },
          {
            field: "PackCode",
            title: "套餐编码",
            sortable: true,
            align: "left",
            width: 100,
          },
          {
            field: "PackName",
            title: "套餐名称",
            sortable: true,
            align: "left",
            width: 160,
          },
          {
            field: "SchdStr",
            title: "排班日期",
            sortable: true,
            align: "center",
            width: 160,
          },
          {
            field: "SchdYears",
            title: "年份",
            sortable: true,
            align: "center",
            width: 100,
          },
          {
            field: "SchdMonth",
            title: "月份",
            sortable: true,
            align: "center",
            width: 80,
          },
          {
            field: "SchdWeek",
            title: "周",
            sortable: true,
            align: "center",
            width: 80,
          },
          {
            field: "IsValidName",
            title: "启用/禁用",
            sortable: true,
            slots: { default: "IsValidName" },
            align: "center",
            width: 100,
          },
          {
            title: "操作",
            width: 160,
            slots: { default: "operate" },
            align: "center",
            fixed: "right",
          },
          //   {
          //     field: "CatName",
          //     title: "类别",
          //     sortable: true,
          //     editRender: { name: "input" },//点击编辑必须配置
          //     slots: { edit: "CatName" },//点击编辑必须配置
          //     align: "left",
          //     width: 140,
          //   },
        ],
        // data: this.PdmMaSchdList,
        data: null,
      },
    };
  },
  mounted() {
    this.onLoadData();
    this.onCreateDate(0);
  },
  methods: {
    ...mapActions("PdmMaSchd", [
      "actGetPdmMaSchdListByPage",
      "actCreatePdmMaSchd",
      "actEditPdmMaSchdData",
      "actEditPdmMaSchd",
      "actDeletePdmMaSchd",
    ]),
    onLoadData() {
      this.actGetPdmMaSchdListByPage({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        FilterString: this.FilterString,
        Years: this.modelYears,
        Month: this.modelMonth,
        PageIndex: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      })
        .then((res) => {
          this.gridOptions.data = res.Data;
          this.tablePage.totalResult = res.Count;
        })
        .finally(() => {
          this.$store.commit("PdmMaSchd/setPdmMaSchdListLoading", false);
          //this.PdmMaSchdListLoading = false;
        });
    },
    onClickItem() {
      if (this.PaiBanOtherData.length <= 0) {
        dlg.alert("请先生成排班");
        return;
      }
      this.$store.commit("PdmMaSchd/setYears", this.modelYears);
      this.$store.commit("PdmMaSchd/setMonth", this.modelMonth);
      this.$store.commit("PdmMaSchd/setPaiBanData", this.PaiBanDate);
      this.$store.commit("PdmMaSchd/setPdmMaSchdShowDlg", true);
    },
    onclickDay(val, val2, val3) {
      console.log("val==>", val);
      console.log("val2==>", val2);
      console.log("val3==>", val3);
      this.$store.commit("PdmMaSchd/setPdmMaSchdListLoading", true);
      Paiban.ClickDay({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        ListInfo: this.PaiBanDate,
        Years: this.modelYears,
        Month: this.modelMonth,
        Reason: val2,
        DetailsDate: val3.year + "/" + val3.month + "/" + val3.day,
      })
        .then((res) => {
          if (res.Code == 0) {
            if (res.OtherData.length <= 0) {
              var dateStr = XEUtils.toStringDate(
                val3.year + "-" + val3.month + "-1",
                "yyyy-MM-dd"
              );
              this.PaiBanOtherData = [];
              this.PaiBanOtherData.push(
                XEUtils.toDateString(dateStr, "yyyy/MM/dd")
              );
              dlg.alert("取消失败！请先选定日期，否则将会被重置为当前月");
            } else {
              this.PaiBanDate = res.Data;
              this.PaiBanOtherData = res.OtherData;
            }

            console.log("排版时间", this.PaiBanOtherData);
          } else {
            dlg.alert(res.Message);
          }
        })
        .finally(() => {
          this.$store.commit("PdmMaSchd/setPdmMaSchdListLoading", false);
        });
    },
    onclickDate(val) {
      this.modelYears = val.year;
      this.modelMonth = val.month;
      this.onCreateDate(0);
    },
    onCreateDate(val) {
      Paiban.CreateDate({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Years: this.modelYears,
        Month: this.modelMonth,
        CreateDateType: val,
      }).then((res) => {
        if (res.Code == 0) {
          this.PaiBanDate = res.Data;
          this.PaiBanOtherData = res.OtherData;
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onCreate() {
      this.actCreatePdmMaSchd({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Years: this.modelYears,
        Month: this.modelMonth,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onIsValid(row) {
      Paiban.UpateIsValid({
        Info: row,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
          this.msg.success(res.Message);
        } else {
          dlg.alert(res.Message);
        }
      });
    },
    onEdit(e) {
      this.$store.commit("PdmMaSchd/setPdmMaSchdEntity", e);
      this.$store.commit("PdmMaSchdtime/setPdmMaSchdtimeShowDlg", true);
    },
    onDelete(e) {
      this.actDeletePdmMaSchd({
        HospID: this.$store.getters["appUser/hospID"],
        CUser: this.$store.getters["appUser/userID"],
        Info: e,
      }).then((res) => {
        if (res.Code == 0) {
          this.onLoadData();
        }
      });
    },
    //onExportData:{},
    //需要合计：表头必须添加 :footer-method="footerMethod" 跟 show-footer
    footerMethod({ columns, data }) {
      var list = ["PRIC"]; //需要汇总的字段
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return "合计";
          }
          if (list.includes(column.property)) {
            return XEUtils.sum(data, column.property);
          }
          return null;
        }),
      ];
      return footerData;
    },
    handlePageChange({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage;
      this.tablePage.pageSize = pageSize;
      this.onLoadData();
    },
  },
};
</script>
