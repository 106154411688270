/*------------------------------------------------
// File Name:PDM_MA_Pack.js
// File Description:PDM_MA_Pack API方法js接口
// Author:zxl
// Create Time:2023/06/06 15:39:47
//------------------------------------------------*/
import { axios } from '@/utils/request'

const api = {
   _maPack: "/PDM_MA_Pack"
}

export default api


//-------------------创建预约套餐对象-------------------
export function CreatePDM_MA_PackEntity(parameter) {
    return axios({
        url: api._maPack + "/CreatePDM_MA_PackEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约套餐列表-------------------
export function GetPDM_MA_PackList(parameter) {
    return axios({
        url: api._maPack + "/GetPDM_MA_PackList",
        method: 'post',
        data: parameter
    })
}

//-------------------分页获取预约套餐列表---------------
export function GetPDM_MA_PackListByPage(parameter) {
    return axios({
        url: api._maPack + "/GetPDM_MA_PackListByPage",
        method: 'post',
        data: parameter
    })
}

//-------------------获取预约套餐对象---------------
export function GetPDM_MA_PackEntity(parameter) {
    return axios({
        url: api._maPack + "/GetPDM_MA_PackEntity",
        method: 'post',
        data: parameter
    })
}


//-------------------保存预约套餐对象---------------
export function UpdatePDM_MA_PackEntity(parameter) {
    return axios({
        url: api._maPack+ "/UpdatePDM_MA_PackEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------删掉预约套餐对象---------------
export function DeletePDM_MA_PackEntity(parameter) {
    return axios({
        url: api._maPack + "/DeletePDM_MA_PackEntity",
        method: 'post',
        data: parameter
    })
}

//-------------------获取上架套餐列表---------------
export function GetIsOffPDM_MA_PackList(parameter) {
    return axios({
        url: api._maPack + "/GetIsOffPDM_MA_PackList",
        method: 'post',
        data: parameter
    })
}

//-------------------获取上架套餐列表---------------
export function GetIsOffPDM_MA_PackList2(parameter) {
    return axios({
        url: api._maPack + "/GetIsOffPDM_MA_PackList2",
        method: 'post',
        data: parameter
    })
}

